<template>
    <div>
        <card-header title="Edit Payment Plan" icon="fa-pencil"/>

        <card-toolbar>
            <button @click="recalculate"><i class="fas fa-calculator mr-2"/>Recalculate Plan</button>
        </card-toolbar>
        <card-body>
            <payment-plan-form :plan="plan"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="false" @click="submit"><i class="fas fa-check mr-3"></i>Save Payment Plan</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import CustomerForm from "@/cards/customers/CustomerForm";
    import PaymentPlanForm from "./PlanForm";
    import CardToolbar from "../../TIER/components/CardToolbar";

    export default {
        props: ['card', 'props'],
        components: {CardToolbar, PaymentPlanForm, CustomerForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function () {
            return {
                plan: {},
                dirty: null
            };
        },
        methods: {
            loadPlan: function () {
                this.$emit('loading', true);
                http.get('/api/payment_plans/' + this.props.planId, {force: true}).then(response => {
                    this.plan = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/payment_plans/' + this.plan.id, this.plan, {force: true}).then(response => {
                    this.$reloadCard('payment-plan', {planId: this.plan.id});
                    this.$reloadCard('registration', {registrationId: this.props.regId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            recalculate: function() {
                if (window.confirm('Do you wish to recalculate this payment plan? This will take the remaining balance and spread it over all un-paid installment plan items.')) {
                    http.put('/api/payment_plans/' + this.plan.id, this.plan, {force: true}).then(response => {
                        http.post('/api/payment_plans/' + this.plan.id + '/recalc', this.plan, {force: true}).then(response => {
                            this.$reloadCard('payment-plan', {planId: this.plan.id});
                            this.$reloadCard('registration', {registrationId: this.props.regId});
                            this.$saveAlert(false);
                            this.$closeCard(this.card);
                        }).catch(err => {

                        });
                    }).catch(err => {

                    });
                }
            }
        },
        watch: {
            'props.planId': function () {
                this.dirty = null;
                this.loadPlan();
            },
            'plan': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this payment plan? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadPlan();
        }
    };
</script>
