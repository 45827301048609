<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Plan Information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Total" label-position="inside">
                        <b-input v-model="plan.total" placeholder="0.00"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Deposit" label-position="inside">
                        <b-input v-model="plan.down" placeholder="0.00"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Total Paid" label-position="inside">
                        <b-input v-model="plan.paid" placeholder="0.00"></b-input>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-tasks" title="Task"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Status" label-position="inside">
                        <b-select expanded v-model="plan.task_status">
                            <option value="planned">Planned</option>
                            <option value="booked">Booked</option>
                            <option value="confirmed">Confirmed</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Payment" label-position="inside">
                        <b-select expanded v-model="plan.task_payment">
                            <option value="unpaid">Unpaid</option>
                            <option value="deposit">Deposit</option>
                            <option value="paid">Paid</option>
                            <option value="na">N/A</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Assignment" label-position="inside">
                        <b-select expanded v-model="plan.task_assigned_id">
                            <option :value="a.id" v-for="a in assignees">{{a.first_name}} {{a.last_name}}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-switch :native-value="1" :true-value="1" :false-value="0" v-model="plan.task_complete">Complete</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Notes" label-position="inside">
                        <b-input type="textarea" v-model="plan.task_notes"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import {mapGetters} from "vuex";

    export default {
        name: 'PaymentPlanForm',
        props: {
            plan: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList},
        computed: {
            ...mapGetters(['assignees'])
        }
    };

</script>

<style scoped>

</style>
